import React, { useMemo } from "react";
import Header from "../../layout/Header/Header";
import NavBar from "../../layout/NavBar/NavBar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAccounting } from "../../hooks";
import { useCloudFunction } from "../../hooks/useCloudFunction";

import "./DetalleObraSocial.css";

function DetalleObraSocial() {
  const {userId, date, organizationName} = useParams();
  const { formatedCurrency } = useAccounting()

  const params = useMemo(() => ({ date, userId, organizationName }), [date, userId, organizationName]);
  // const url = 'https://getConsultationsByDateAndOrganization-g7u5c6xydq-uc.a.run.app/';
  const url = 'http://127.0.0.1:5001/tani-3a53a/us-central1/getConsultationsByDateAndOrganization';
  const { datos, cargando } = useCloudFunction(url, params);

  if (cargando) {
    return <div>Cargando...</div>;
  }
  console.log(datos);
  const parsedDate = new Date(datos.date + 'T03:00:00Z'); // work around para que no me tome el date time como UTC
  const options = {
    timeZone: "America/Argentina/Buenos_Aires",
  };
  
  // Obtener mes
  const monthName = parsedDate.toLocaleString("es-ES", { 
    ...options,
    month: "long"
  }).toUpperCase();
  
  // Obtener día numérico
  const day = parsedDate.toLocaleString("es-ES", { 
    ...options,
    day: "numeric"
  });
  
  // Obtener día de la semana
  const dayOfWeek = parsedDate.toLocaleString("es-ES", { 
    ...options,
    weekday: "long"
  }).toUpperCase();

  return (
    <div className="summary-container">
      <Header title={"Procesado"} isSubscripter={false} />
      <NavBar />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 20px 40px 20px",
        }}
      >
        <p
          style={{
            margin: "0",
            fontWeight: "bold",
            color: "#2AA726",
            fontSize: "16px",
          }}
        >
          {monthName}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "end",
          }}
        >
          <p style={{ margin: "0", color: "#777676", lineHeight: "1" }}>
            {dayOfWeek} {day}
          </p>
          <p style={{ margin: "0", color: "#777676" }}>{formatedCurrency(datos.totalSum)}</p>
        </div>
      </div>

      <div>
        <h1
          style={{
            padding: "0 20px",
            fontWeight: "bold",
            color: "#a89d7f",
            marginBottom: "0px",
            fontSize: "20px",
          }}
        >
          {organizationName}
        </h1>

        <div className="tabla-pacientes">
          <div className="tabla-header">
            <div className="columna" style={{ flex: "2" }}>
              PACIENTE
            </div>
            <div
              className="columna align-right"
              style={{ color: "rgb(119, 118, 118)" }}
            >
              ORDENES
            </div>
            <div
              className="columna align-right"
              style={{ color: "rgb(119, 118, 118)" }}
            >
              MONTO
            </div>
          </div>
          {datos.claims.map((claim, index) => (
            <div
              key={index}
              className="tabla-row"
              style={{ color: "rgb(119, 118, 118)" }}
            >
              <div className="columna paciente-nombre" style={{ flex: "2" }}>
                <a style={{'textDecoration': 'none'}} href={`/patient-detail/${userId}/${claim.id}`}>{claim.patient}</a>
              </div>

              <div className="columna align-right">{claim.claim}</div>
              <div className="columna align-right">{formatedCurrency(claim.plus)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DetalleObraSocial;
