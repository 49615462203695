import React, { useMemo } from "react";
import Header from "../../layout/Header/Header";
import NavBar from "../../layout/NavBar/NavBar";
import "./ResumenProcesado.css";
import DonutChart from "../DonutChart/DonutChart";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useCloudFunction } from "../../hooks/useCloudFunction";
import { useAccounting } from "../../hooks";

const ResumentProcesado = () => {

  const {userId, date} = useParams();
  const { formatedCurrency } = useAccounting()
  const params = useMemo(() => ({ date, userId }), [date, userId]);
  const url = 'https://processedSummary-g7u5c6xydq-uc.a.run.app/';
  // const url = 'http://127.0.0.1:5001/tani-3a53a/us-central1/processedSummary';
  const { datos, error, cargando } = useCloudFunction(url, params);

  if (cargando) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return (
      <div className="summary-container">
        <Header title={"Procesado"} isSubscripter={true} />
        <div>Error: {error}</div>
      </div>
    );
  }

  const [year, month, _day] = datos.date.split("-").map(Number);
  const parsedDate = new Date(year, month - 1, _day) // work around para que no me tome el date time como UTC
  const monthName = parsedDate.toLocaleString("es-ES", { month: "long"}).toUpperCase();
  const day = parsedDate.getDate();
  const dayOfWeek = parsedDate.toLocaleDateString("es-ES", { weekday: "long" }).toUpperCase();
  const amount = datos.pieData.reduce((sum, _) => sum + 1, 0);

  return (
    <div className="summary-container">
      <Header title={"Procesado"} isSubscripter={false} />
      <NavBar />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 20px 10px 20px",
        }}
      >
        <p
          style={{
            margin: "0",
            fontWeight: "bold",
            color: "#2AA726",
            fontSize: "16px",
          }}
        >
          {monthName}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "end",
          }}
        >
          <p
            style={{
              margin: "0",
              fontWeight: "bold",
              color: "#2AA726",
              fontSize: "16px",
            }}
          >
            {dayOfWeek} {day}
          </p>
          <p style={{ margin: "0", color: "#777676" }}>{formatedCurrency(datos.total)}</p>
        </div>
      </div>

      <div
        style={{ padding: "10px 20px", display: "flex", alignItems: "center" }}
      >
        <span
          className="obra-social"
          style={{
            color: "#5e5e5e",
            fontWeight: "bold",
            fontSize: "18px",
            marginRight: "4px",
          }}
        >
          OBRA SOCIAL
        </span>
        <span className="barra"></span>
        <span
          style={{
            color: "rgb(176 177 176)",
            fontSize: "18px",
            marginLeft: "4px",
          }}
        >
          PREPAGA
        </span>
      </div>

      <div style={{ padding: "0 20px" }}>
        <table className="tabla-datos">
          <thead>
            <tr>
              <th style={{ textAlign: "end" }} colSpan="2">
                ORDENES
              </th>
              <th style={{ width: "85px" }}>MONTO</th>
            </tr>
          </thead>
          <tbody>
            {datos.summary.map((item, index) => (
              <tr key={index}>
                <td className="nombre"><a style={{'textDecoration': 'none'}} href={`/processed-summary-detail/${userId}/${date}/${item.organization}`}>{item.organization}</a></td>
                <td>{item.cantidad}</td>
                <td>{formatedCurrency(item.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <DonutChart labelColor="#2AA726" labelDistance={1.2} fontSize={14} data={datos.pieData} total={datos.total} amount={amount} />
      </div>
    </div>
  );
};

export default ResumentProcesado;
