import React from "react";
import { Search } from "antd-mobile";
import { AddCircleOutline } from "antd-mobile-icons";

const NavBar = ({ children, left, right, onBack, back }) => {
    return (
        <nav
        style={{
          background: "#a89d7f",
          height: "60px",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "20px",
          padding: "10px 20px",
        }}
      >
        <Search style={{ width: "100%" }} />
        <AddCircleOutline style={{ fontSize: "40px", color: "white" }} />
      </nav>
    );
};

export default NavBar;